// In src/pages/Page.js
import React from 'react'

import './style/1018360.scss';



const Popular1018360 = ({ match }) => {


    return (
        <div className="container-1018360">
            <p>pop 1018360</p>
        </div>
    )
}

export default Popular1018360;