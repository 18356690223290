// In src/pages/Page.js
import React from 'react'

import './style/160600.scss';



const Popular160600 = ({ match }) => {


    return (
        <div className="container-160600">
            <p>sogt 160600</p>
        </div>
    )
}

export default Popular160600;