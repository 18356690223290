// In src/pages/Page.js
import React from 'react'
import './style/310400.scss';
import products from './products.json';
import logo from '../assets/logo.svg';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const Sogtaeki310400 = ({ match }) => {
    return (
        <div className="sog-310400">
            <div className="upper">
                <img src={logo} alt="blush.is" className="logo" />
                <p>Hefur þú prófað</p>
                <h1>Sogtæki?</h1>
            </div>
            <AliceCarousel
                items={products.map(p =>
                    <div className="product" onClick={e => window.open(`https://blush.is/product/${p.slug}`)}>
                        <img src={p.image} alt={p.slug} />
                        <div className="label">
                            <p>{p.brand.name}</p>
                            <b>{p.name}</b>
                        </div>
                        <div className="price">
                            <b>{p.price_from} kr.</b>
                        </div>
                    </div>
                )}
                mouseTracking
                autoPlay
                infinite
                autoPlayInterval={2400}
            />
        </div>
    )
}

export default Sogtaeki310400;