import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { Sogtaeki160600, Sogtaeki310400, Sogtaeki1018360 } from './sogtaeki';
import { Inpage160600, Inpage310400, Inpage1018360, Inpage300250 } from './inpage';
// import Popular310400 from './popular/310400';
// import Popular160600 from './popular/160600';
// import Popular1018360 from './popular/1018360';

function App() {
    return (
        <BrowserRouter>
            <Switch>
                {/* <Route exact path='/popular/310400' component={Popular310400} />
        <Route exact path='/popular/160600' component={Popular160600} />
        <Route exact path='/popular/1018360' component={Popular1018360} /> */}

                <Route exact path="/sogtaeki/160600" component={Sogtaeki160600} />
                <Route exact path="/sogtaeki/310400" component={Sogtaeki310400} />
                <Route exact path="/sogtaeki/1018360" component={Sogtaeki1018360} />

                <Route exact path="/inpage/160600" component={Inpage160600} />
                <Route exact path="/inpage/310400" component={Inpage310400} />
                <Route exact path="/inpage/300250" component={Inpage300250} />
                <Route exact path="/inpage/1018360" component={Inpage1018360} />
            </Switch>
        </BrowserRouter>
    );
}

export default App;
